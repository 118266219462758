<!--
 * @Author: wch
 * @Date: 2020-07-31 14:54:29
 * @LastEditTime: 2021-03-05 11:31:38
 * @LastEditors: Please set LastEditors
 * @Description: 故障数量统计
 * @FilePath: \trunk\src\pages\equipmentState\berthStateTotal\faultNumber.vue
-->
<template>
  <div>
    <graphTitle title="故障数量统计" class="paddingLR20 paddingT10">
    </graphTitle>
    <div id="faultNumber" class="faultNumber"></div>
  </div>
</template>

<script>
import graphTitle from "@/components/graphTitle";

export default {
  components: {
    graphTitle
  },
  data() {
    return {
      dataList: {
        overData: [],
        errorData: [],
      },
      xAxisData: [],
    };
  },
  props: {
    faultNumber: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    faultNumber: {
      handler(value) {
        this.dataList = {
          overData: [],
          errorData: [],
        };
        this.xAxisData = Object.keys(value);
        for (let i = 0; i < this.xAxisData.length; i++) {
          this.dataList.overData.push(
            value[this.xAxisData[i]].finishFaultCount
          );
          this.dataList.errorData.push(value[this.xAxisData[i]].faultCount);
        }
        // this.dataList.yData = value.yData;
        // this.dataList.seriesData = value.seriesData;
        this.drawGraph();
      },
      deep: true,
    },
  },
  // 方法集合
  methods: {
    drawGraph() {
      // let that = this;
      let faultNumber = this.$echarts.init(
        document.getElementById("faultNumber")
      );
      let option = {
        // title: {
        //   text: "故障数量统计",
        //   x: "center",
        //   top: 10
        // },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            return (
              params[0].name +
              "<br>" +
              params[0].marker +
              params[0].seriesName +
              "：" +
              params[0].value +
              "次<br>" +
              params[1].marker +
              params[1].seriesName +
              "：" +
              params[1].value +
              "次"
            );
          },
        },
        legend: {
          data: ["完工次数", "故障次数"],
          right: "4%",
          top: 10
        },
        color: ["#FF9D9D", "#918FFF"],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xAxisData,
          axisLabel: {
            interval: true,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: "完工次数",
            type: "line",
            smooth: true,
            data: this.dataList.overData,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255,120,120,0.35)"
                },
                {
                  offset: 1,
                  color: "rgba(255,120,120,0.06)"
                }
              ])
            }
          },
          {
            name: "故障次数",
            type: "line",
            smooth: true,
            data: this.dataList.errorData,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(145, 143, 255, 0.35)"
                },
                {
                  offset: 1,
                  color: "rgba(145, 143, 255, 0.06)"
                }
              ])
            }
          },
        ],
      };
      faultNumber.setOption(option, true);
    },
  },
  created() {},
  mounted() {
    this.drawGraph();
  },
};
</script>
<style lang="stylus" scoped>
// @import url(); 引入公共css类
.faultNumber
  width: 100%;
  height: 300px;
  overflow: hidden;
</style>
