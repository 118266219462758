<!--
 * @Author: wch
 * @Date: 2020-07-30 18:19:58
 * @LastEditTime: 2021-03-05 11:28:47
 * @LastEditors: Please set LastEditors
 * @FilePath: F:\智慧互通\beijing\Acdp\trunk\src\pages\equipmentState\berthStateTotal\berthStateTotal.vue
 * @Description: 历史统计
-->
<template>
  <div class="page1">
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper marginB20">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          label-width="96px"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Road_name')">
                <my-component
                  ref="parkInput"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                  :commercialStatus="'2,3'"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.date')" prop="showDate" class="day_month">
                <el-select
                  v-model.trim="formInline.showDate"
                  filterable
                  @change="changeDateType()"
                  size="15"
                >
                  <el-option label="日" value="day">日</el-option>
                  <el-option label="月" value="month">月</el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="time_items">
                <el-date-picker
                  class="time-picker"
                  v-model="day"
                  type="daterange"
                  :picker-options="pickerOptions"
                  unlink-panels
                  :editable="false"
                  :clearable="false"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  v-show="formInline.showDate == 'day'"
                ></el-date-picker>
                <el-date-picker
                  v-model="month"
                  :clearable="false"
                  class="time-picker"
                  type="monthrange"
                  :picker-options="pickerOptions1"
                  placeholder="选择月"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  v-show="formInline.showDate == 'month'"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="graphShadow marginB20">
        <faultNumber :faultNumber="faultNumber"></faultNumber>
      </div>
      <div>
        <el-row :gutter="20" class="list commonFa">
          <el-col :span="12">
            <div class="grid-content bg-purple graphShadow">
              <graphTitle title="实际故障类型占比" class="paddingLR20 paddingT10">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="“完工”工单的实际故障类型占比"
                  placement="bottom-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </graphTitle>
              <berthState :type="type" :memberCarNumber="memberCarNumber"></berthState>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="graphShadow">
              <graphTitle title="故障道路排名" class="paddingLR20 paddingT10">
              </graphTitle>
              <faultPlate :faultPlate="faultPlate"></faultPlate>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import myComponent from "@/components/autocomplete/myautoComponent";
import faultNumber from "./faultNumber";
import berthState from "../berthStateStatistics/berthState";
import faultPlate from "../berthStateStatistics/faultPlate";
import graphTitle from "@/components/graphTitle";

import { dateFormat } from "@/common/js/public";
let start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 30);
let end = new Date();
end.setTime(end.getTime() - 3600 * 1000 * 24);
export default {
  data() {
    const newDate = new Date();
    newDate.setTime(newDate.getTime() - 3600 * 1000 * 24);
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > newDate;
        },
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > end.getTime() - 31 * 24 * 3600 * 1000;
        },
      },
      day: [start, end],
      month: [start, end],
      formInline: {
        parkId: "",
        parkName: "",
        showDate: "day",
      },
      type: "c2",
      faultNumber: {},
      memberCarNumber: [],
      faultPlate: [],
    };
  },
  methods: {
    changeDateType() {
      const start = new Date();
      const end = new Date();
      if (this.formInline.showDate === "day") {
        start.setTime(start.getTime() - 30 * 24 * 3600 * 1000);
        end.setTime(end.getTime() - 24 * 3600 * 1000);
        this.day = [start, end];
      } else {
        start.setTime(start.getTime() - 31 * 24 * 3600 * 1000);
        end.setTime(end.getTime());
        this.month = [start, end];
      }
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    searchData() {
      let startDate =
        this.formInline.showDate === "day"
          ? dateFormat(this.day[0], "yyyy-MM-dd")
          : dateFormat(this.month[0], "yyyy-MM");
      let endDate =
        this.formInline.showDate === "day"
          ? dateFormat(this.day[1], "yyyy-MM-dd")
          : dateFormat(this.month[1], "yyyy-MM");
      var time = new Date(endDate) - new Date(startDate);
      var timeMonth = new Date(endDate).getMonth() - new Date(startDate).getMonth();
      var timeYear = new Date(endDate).getFullYear() - new Date(startDate).getFullYear();
      time = time / (24 * 60 * 60 * 1000);
      timeMonth = timeMonth + timeYear * 12;
      if (this.formInline.showDate === "day" && time >= 31) {
        this.$alert("查询时间不能超过31天");
        return false;
      } else if (this.formInline.showDate === "month" && timeMonth > 1) {
        this.$alert("查询时间不能超过1个月");
        return false;
      }
      // this.$axios
      //   .get("/acb/2.0/bigDataCount/operationnum", {
      //     data: {
      //       parkId: this.formInline.parkId,
      //       dateType: this.formInline.showDate === "day" ? "1" : "2",
      //       startDate,
      //       endDate,
      //     },
      //   })
      //   .then((res) => {
      //     if (res.state == 0) {
      //       this.operationNum = res.value ? res.value : "";
      //     } else {
      //       this.$alert(res.desc, this.$t('pop_up.Tips'), {
      //         confirmButtonText: this.$t('pop_up.Determine'),
      //       });
      //     }
      //   });
      this.operationpark();
    },
    operationpark() {
      let startDate =
        this.formInline.showDate === "day"
          ? dateFormat(this.day[0], "yyyy-MM-dd")
          : dateFormat(this.month[0], "yyyy-MM");
      let endDate =
        this.formInline.showDate === "day"
          ? dateFormat(this.day[1], "yyyy-MM-dd")
          : dateFormat(this.month[1], "yyyy-MM");
      this.faultNumber = {};
      this.memberCarNumber = [];
      this.faultPlate = [];
      this.getFaultTrend(startDate, endDate);
      this.getFaultTypeRatio(startDate, endDate);
      this.getFaultParkRank(startDate, endDate);
    },
    // 日、月上报故障次数和完工次数
    getFaultTrend(startDate, endDate) {
      this.$axios
        .get("/acb/2.0/berthStatistics/faultTrend", {
          data: {
            parkId: this.formInline.parkId,
            dateType: this.formInline.showDate === "day" ? "1" : "2",
            startDate,
            endDate,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.faultNumber = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 实际故障占比
    getFaultTypeRatio(startDate, endDate) {
      this.$axios
        .get("/acb/2.0/berthStatistics/faultTypeRatio", {
          data: {
            parkId: this.formInline.parkId,
            dateType: this.formInline.showDate === "day" ? "1" : "2",
            startDate,
            endDate,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.memberCarNumber = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // top5
    getFaultParkRank(startDate, endDate) {
      this.$axios
        .get("/acb/2.0/berthStatistics/faultParkRank", {
          data: {
            parkId: this.formInline.parkId,
            dateType: this.formInline.showDate === "day" ? "1" : "2",
            startDate,
            endDate,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.faultPlate = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // handleCurrentChange(currentpage) {
    //   this.pageNum = currentpage;
    //   this.searchData();
    // },
  },
  components: {
    myComponent,
    faultNumber,
    berthState,
    faultPlate,
    graphTitle,
  },
  mounted() {
    this.searchData();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">

::v-deep .el-form-item.time_items {
 .el-date-editor.el-range-editor {
  width: 280px
  .el-range-input {
    width: 90px
  }
  .el-range-separator {
    width: 30px
  }
 }
}


.el-icon-date-custom{
  position: relative
  left: -26px;
}
::v-deep .el-select {
  width: 100% !important
}
.content
  overflow hidden;
  .searchWrapper
    overflow hidden
    // padding 22px 22px 0;
    .orderBox
      border 1px solid #cccccc;
  .dataAn-out
    position relative;
    width 18%;
    display inline-block;
    padding-left 13px;
    padding-right 13px;
    text-align center;
    margin-bottom 20px;
  .dataAn-text{
    width: 100%;
    height: 100px;
    color: #333;
    border-radius: 5px;
  }
  .dataAn-text p
    font-size 18px;
  .dataAn-name
    position relative;
    padding-top 22px;
  .dataAn-count
    position absolute;
    left 0;
    right 0;
    top: 68px;
  .dataAn-img
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #E1E1E1;
    color white;
    line-height: 22px;
    border-radius: 50%;
    position: absolute;
    top: 22px;
  .dataAn-f
    background-color: #E1E1E1;
    position: absolute;
    padding: 10px;
    left: 22%;
    top: -36px;
    z-index: 10;
    width: 185px;
</style>
